.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Global font size */
body {
  font-size: 13px; /* Adjust the size as needed */
  /* font-family: 'YourPreferredFont', sans-serif; */
}



.wo-infotip,
.wo-wp {
  margin: 15px;
  margin-top: 25px;

  table {
    width: 100%;
  }
}

.table-header-row th {
  padding: 10px;
}

.table-body-row td {
  text-align: center;
  font-size: small;
}

.update {
  height: fit-content;
  /* background-color: rgb(255, 124, 64, 0.7); */
  border-radius: 10px;
  border: solid 1px rgb(112, 110, 109);
  padding: 0 1%;
  width: 8%;
}
.action {
  height: fit-content;
  background-color: rgb(255, 124, 64, 0.7);
  border-radius: 10px;
  border: solid 1px rgb(255, 124, 64, 0.7);
  padding: 0 1%;
  /* width: 50%; */
}
.green-status {
  color: rgb(14, 172, 14);
}

.red-status {
  color: rgb(182, 1, 1);
}

.select-entries {
  margin: 0 5px;
}

.pagination-buttons {
  display: flex;
  align-items: center;
  justify-self: end;
  gap: 15px;
  padding: 0 10px;

  button {
    border: none;
    background-color: white;
    color: grey;
    cursor: pointer;
  }

  .current-page-button {
    color: #337ab7;
    font-weight: 600;
    text-decoration: underline;
  }
}

.create-order {
  margin: 50px 0 0 15px;
  display: flex;
}

.create-button {
  margin-right: 15px;
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #337ab7;
  color: white;
  font-size: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.create-order-list {
  margin-left: 10%;
}

.create-order-list li {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.create-order-list input,
.create-order-list select,
.create-order-list .selected-date {
  background-color: rgb(214, 228, 235);
  border: solid 0.5px rgb(119, 167, 187);
  width: 200px;
  box-sizing: border-box;
}

.create-order-list .selected-date {
  padding-left: 5px;
}

.create-order-list .contact {
  padding-left: 20%;
}

.dropdown-calendar-container {
  position: absolute;
  z-index: 1;
}

.create-order-buttons {
  display: flex;
  justify-content: center;
  margin: 3%;
}

.confirm {
  margin: 0 10px;
  padding: 3px 10px;
  background-color: rgb(255, 231, 200);
  border: solid 1px black
}

.listItemStyle {
  display: grid;
  grid-template-columns: minmax(150px, auto) 1fr;
  gap: 10px;
  align-items: baseline;
}

.labelStyle::before {
  content: "•";
  font-size: medium;
  margin-right: 5px;
}

.available-attachment-table {
  width: 70%;
  border-collapse: collapse;
  margin: 10px 0 0 0;
  color: rgba(0, 0, 0, 0.7);

  th {
    border: 1px solid rgba(255, 166, 0, 0.9);
    padding: 8px;
    text-align: left;
    background-color: rgba(253, 169, 14, 0.5);
  }

  td {
    border: 1px solid rgba(255, 166, 0, 0.9);
    padding: 8px;
    text-align: left;
  }
}

.blue-row {
  background-color: rgba(209, 230, 238, 0.2);
}






/* imageModal.css */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: rgb(131, 131, 131);
  padding: 15px;
  border-radius: 4px;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  overflow: hidden;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom-image {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease;
}

.zoom-controls {
  margin-top: 10px;
}

.zoom-button {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 10px;
  background-color: rgb(99, 99, 99);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: larger;
}

.zoom-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.close-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.close-button {
  width: 40px;
  height: 40px;
  margin: 5px;
  padding: 5px;
  background-color: rgb(99, 99, 99);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}


select {
  background-color: #f8f9fa; /* Default background color of the select box */
  color: #333; /* Default text color */
  padding: 0px 15px; /* Optional: adjust padding */
  border: 1px solid #ccc; /* Optional: border for the select */
}

select option:checked {
  background-color: white; /* Active option background color */
  color: white; /* Text color for the selected option */
}

select option:hover {
  background-color: #e9ecef; /* Background color on hover */
}
