.dashboard-table-container .table-item input,
.dashboard-table-container .table-item select {
  height: 40px; /* Adjust this value as needed */
  line-height: 1.2; /* Ensure text is vertically aligned */
  width: 170px; 
  border: 1px solid black;
}


.filled-button {
    display: inline-block;
    padding: 4px 16px;
    /* font-size: 14px; */
    font-weight: bold;
    color: #fff;
    background-color: #337ab7;
    border: 2px solid #337ab7;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    margin-right: 15px;
    margin-left: 15px;
    /* width: 40%; */
  }
  
  .clear-button {
    display: inline-block;
    padding: 4px 16px;
    font-weight: bold;
    background-color: white;
    border: 2px solid #337ab7;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  .tab-panel {
    padding: 10% 5% 20% 5%;
    background-color: #00308fe0;
    color: white;
  
    .active-panel {
      font-weight: 600;
    }
  }
  
  .tabs-container {
    padding: 1% 0px;
  }
  
  .tabs {
    margin-left: 1%;
    display: flex;
    gap: 20px;
    align-items: baseline;
  }
  
  .uline {
    text-decoration: underline;
  }
  
  .curPo {
    cursor: pointer;
  }
  
  .active {
    background-color: #154d7e;
    color: white;
    padding: 3px 10px;
    position: relative;
    font-weight: 600;
  }
  
  .active:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 5px solid white;
    border-left: 5px solid #154d7e;
    width: 0;
  }
  
  .active-close-button,
  .inactive-close-button {
    background-color: transparent;
    border: none;
    font-size: large;
  }
  
  .active-close-button {
    color: white;
  }
  
  .tab-content {
    border: solid 1.5px rgb(173, 173, 204);
  }
  
  .dashboard-container {
    margin: 15px;
  }
  
  .dashboard-table-container {
    padding: 20px;
    border-style: solid;
    border-color: rgb(213, 211, 211);
  }
  
  .table-top {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1%;
    padding-left: 10px;
  }
  
  .table-item {
    display: flex;
    justify-self: end;
    margin-left: 3%;
    margin-right: 2%;
    margin-bottom: 10px;
  }
  
  .table-item input {
    height: 15px;
    width: 120px;
    margin-left: 10px;
    border: solid 2px;
    border-color: skyblue;
    padding: 2px;
  }
  
  .table-item select {
    height: 25px;
    width: 128px;
    margin-left: 10px;
    border: solid 2px;
    border-color: skyblue;
  }
  
  .large {
    grid-column: span 2;
    justify-self: flex-start;
    /* padding-left: 50px; */
    /* margin-left: 30px; */
  }

  .table-bottom {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  
  .table-bottom-right {
    display: flex;
  }
  
  .dashboard-button:hover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  }
  
  .dashboard-button:active {
    padding: 3px 12px;
  }

  