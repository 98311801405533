body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: xs-small;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  display: none;
}
/* Set default background color for the navbar */
.navbar {
  background-color: #337ab7; /* Default color for the navbar */
  padding: 10px 0; /* Optional: Adjust the padding */
}

.navbar .nav-link {
  color: white; /* Default text color for links */
  text-decoration: none;
  padding: 10px 15px;
  margin: 0 15px;
}
.navbar button {
  background-color: #337ab7; /* Set the background color (blue as an example) */
  color: white; /* Text color */
  border: 1px solid #337ab7; /* Border color */
}

.navbar a {
  color: white; /* Text color */
  text-decoration: none; /* Remove underline */
}

.navbar a:hover {
  color: white; /* Darker shade on hover for navigation items */
  background-color: #154d7e;
}
