.select-styled, .input-styled {
  background-color: rgb(214, 228, 235);
  border: solid 0.5px rgb(119, 167, 187);
  width: 150px;
  box-sizing: border-box;
  margin-left: 5px;
}

.checkbox-styled {
  background-color: rgb(214, 228, 235);
  border: solid 0.5px rgb(119, 167, 187);
  width: 15px;
  height: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding-left: 1px;
}

.checkbox-styled:checked::after {
  content: "✓";
  font-weight: 600;
}

.assign-technician-container {
  border: solid 3px rgb(172, 172, 255);
  padding: 3%;
}

.assign-form {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;

  select {
    width: 200px;
    border: solid 1px;
    border-color: rgb(98, 98, 243);
  }
}

.defect-tv-sn {
  grid-column: span 4;
  width: 188px;
  border: solid 1px;
  border-color: rgb(98, 98, 243);
  padding: 1px 5px;
  margin-left: 5px;
}

.confirm-cancel-buttons {
  display: flex;
}

.confirm {
  margin: 0 10px;
  padding: 3px 10px;
  background-color: rgb(255, 231, 200);
  border: solid 1px black
}

.cancel {
  margin: 0 10px;
  padding: 3px 10px;
  background-color: white;
  border: solid 1px black
}

.reassign-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  .reassign-button{
    /* width: fit-content;  */
    padding: 3px 10px;
    border: solid 1px black;
    background-color: rgba(202, 231, 202, 0.6);
  }

  select{
    width: 150px;
  }
}

.assign-history-table {
  margin: 20px 10px;
  border-collapse: collapse;
}

.assign-history-table th, .assign-history-table td {
  border: 1px solid #7c7c7c;
  padding: 8px;
  text-align: left;
}

.order-completion-container {
  border: solid 3px rgb(172, 172, 255);
  padding: 2%;
  display: flex;
  flex-direction: column;

  .order-completion-table {
    margin: 20px 10px;
    border-collapse: collapse;
  }
  
  .order-completion-table th, .order-completion-table td {
    border: 1px solid #7c7c7c;
    padding: 8px;
    text-align: left;
  }
}

.handling-history-container {
  border: solid 3px rgb(172, 172, 255);
  padding: 2%;
  display: flex;
  flex-direction: column;

  .handling-history-table {
    margin: 20px 10px;
    border-collapse: collapse;
  }
  
  .handling-history-table th, .handling-history-table td {
    border: 1px solid #7c7c7c;
    padding: 8px;
    text-align: left;
  }
}

.order-completion-bottom {
  display: flex;
  gap: 5px;
  align-items: center;
}

.toggle-box {
  display: flex;
  align-items: center;
  background-color: rgba(234, 241, 243, 0.993);
  margin: 5px;
  max-height: 30px;

  button {
    height: min-content;
    margin: 10px;
    background-color: rgba(209, 230, 238, 0.925);
    border: solid 1px rgba(127, 211, 241, 0.884);
    font-weight: 800;
  }
}

.address {
  display: flex;
  gap: 10%;
  align-items: baseline;
}

.plan-request-date-container {
  display: flex;
  align-items: baseline;
  gap: 2%;

  button {
    height: fit-content;
  }

  select {
    height: fit-content;
  }
}

.plan-request-date {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectBox {
  position: relative;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
}

#checkboxes label {
  display: block;
}

.react-datepicker.custom-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
  height: 100px;
  width: 100px;
}

.diagnose-list, .repair-list {
  padding: 0;

  li {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    input,
    select {
      background-color: rgb(214, 228, 235);
      border: solid 0.5px rgb(119, 167, 187);
    }

    select, input {
      width: 50%;
      height: 100%;
    }
  }
}

.comment-input {
  margin-top: 15px;

  input {
    width: 100% !important;
    background-color: rgb(214, 228, 235);
    border: solid 0.5px rgb(119, 167, 187);
  }
}

.photos-table, .files-table{
  th, td{
    text-align: start;
  }
}

.spare-parts-list {
  padding: 0;

  li {
    display: grid;
    grid-template-columns: repeat(8, 1fr);

    input,
    select {
      background-color: rgb(214, 228, 235);
      border: solid 0.5px rgb(119, 167, 187);
      width: 100%;
      height: 100%;
    }
  }
}

.after-repair-table, .packaging-table {
  margin: 20px 10px;
  border-collapse: collapse;
  width: 50%;
}

.after-repair-table th, .after-repair-table td, .packaging-table th, .packaging-table td {
  border: 1px solid #7c7c7c;
  padding: 8px;
  text-align: left;
}