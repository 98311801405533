/* Basic styling for the container */
.container1 {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Styling for the input fields */
  /* .form-group {
    margin-bottom: 15px;
  } */
  
  .form-control {
    width: 100%;
    max-width: 300px; /* Adjust as needed */
  }
  
  .input-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  
  /* Styling for the labels */
  /* label {
    font-weight: bold;
  } */
  
  /* Styling for the row */
  /* .row {
    margin-bottom: 10px;
  } */
  
  /* Styling for error messages */
  .error-message {
    color: red;
  }
  
  /* Styling for icons */
  .eye, .info, .eye1, .info1 {
    cursor: pointer;
    margin-left: 10px;
  }
  
  .eye {
    width: 20px;
    height: 20px;
  }
  
  .info {
    width: 20px;
    height: 20px;
  }
  
  .eye1 {
    width: 20px;
    height: 20px;
  }
  
  .info1 {
    width: 20px;
    height: 20px;
  }
  
  /* Styling for the register button */
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
  }
  
  /* Popup form styling */
  .form-popup {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
  }
  
  .form-container {
    max-width: 300px;
    padding: 20px;
    background-color: white;
  }
  
  /* Close button styling */
  .close-btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-btn2 {
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  
  .close-btn2:hover {
    background-color: darkred;
  }
  .link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  